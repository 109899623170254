import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentRenderer from "../components/ContentRenderer"
import Footer from "../components/Footer"

const IndexPage = ({ data }) => {

  return (
    <Layout altNav={true}>
      <Seo title="Home" />

      {/* <Slider /> */}
      <ContentRenderer node={data.allStrapiDynamicPage.edges[0].node} />

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiDynamicPage(filter: { Slug: { eq: "home" } }) {
      edges {
        node {
          Content
        }
      }
    }
  }
`

export default IndexPage
